import "./Hero1.css";
import "./HeroAnimations.css";
import GoldenBricks from "../../../assets/svg/gold-bricks.svg";
import GoldCoin1 from "../../../assets/svg/gold-coin-1.svg";
import GoldCoin2 from "../../../assets/svg/gold-coin-2.svg";
import GoldCoin3 from "../../../assets/svg/gold-coin-3.svg";
import Ethereum from "../../../assets/svg/ethereum.png";
import Bitcoin from "../../../assets/svg/bitcoin.svg";
import Dollar from "../../../assets/svg/hero-dollar.svg";
import Pound from "../../../assets/svg/pound.svg";
import Tesla from "../../../assets/svg/tesla.svg";
import Euro from "../../../assets/svg/euro.svg";
import Google from "../../../assets/svg/google.svg";
import Apple from "../../../assets/svg/apple.svg";
import { Link } from "react-router-dom";
import Button from "../../Common/Button/Button";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import i18next from "i18next";

const Hero1 = () => {
  const { t } = useTranslation();
  const [registrationLink, setRegistrationLink] = useState("");
  const [redirectLang, setRedirectLang] = useState("");
  const [showNotice, setShowNotice] = useState(true);

  useEffect(() => {
    if (i18next.language === "ar") {
      setRedirectLang("?lead_source=website");
    } else {
      setRedirectLang("?lead_source=website");
    }
  
    if (
      window.location.hostname === "supremefxtrading.sv" ||
      window.location.hostname === "www.supremefxtrading.sv"
    ) {
      setRegistrationLink(
        `https://secure.supremefxtrading.sv/register${redirectLang}`
      );
      setShowNotice(false); // Hide notice bar
    } else if (
      window.location.hostname === "supremefxtrading.net" ||
      window.location.hostname === "www.supremefxtrading.net"
    ) {
      setRegistrationLink(`https://secure.supremefxtrading.net/register`);
      setShowNotice(false); // Hide notice bar
    } else {
      setRegistrationLink(
        `https://secure.supremefxtrading.com/register${redirectLang}`
      );
      setShowNotice(true); // Show notice bar only for .com
    }
  }, [i18next.language, redirectLang]);
  
  return (
    <div className="hero-container">
      {showNotice && (
        <div className="notice-bar ">
          <p>
            <strong>High Risk Warning:</strong> CFD trading carries a high level
            of risk that may not be suitable for all investors. Leverage creates
            additional risk and loss exposure. Before you decide to trade
            foreign exchange, carefully consider your investment objectives,
            experience level, and risk tolerance.
          </p>
        </div>
      )}

      <div className="hero-section">
        <div className="hero-content">
          <h1 className="text-capitalize">{t("your trusted broker")}</h1>
          <p className="p1-24">{t("regulated, secure & smart")}</p>
          <Link to={registrationLink} target="_blank" rel="noreferrer">
            <Button className="theme-pink-btn" btnTxt={t("Get Started")} />
          </Link>
        </div>

        <div className="golds">
          <img src={GoldenBricks} alt="" className="golden-bricks" />
          <img src={GoldCoin1} alt="" id="golden-coin1" />
          <img src={GoldCoin2} alt="" id="golden-coin2" />
          <img src={GoldCoin3} alt="" id="golden-coin3" />
        </div>

        <div className="left-icons">
          <img src={Ethereum} alt="" id="ethereum-icons" />
          <img src={Bitcoin} alt="" id="bitcoin-icons" />
          <img src={Dollar} alt="" id="dollar-icons" />
          <img src={Pound} alt="" id="pound-icons" />
        </div>

        <div className="right-icons">
          <img src={Tesla} alt="" id="tesla-icons" />
          <img src={Euro} alt="" id="euro-icons" />
          <img src={Google} alt="" id="google-icons" />
          <img src={Apple} alt="" id="apple-icons" />
        </div>
      </div>
    </div>
  );
};

export default Hero1;
