import Navigation from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";
import ScrollTop from "../Common/ScrollTop/ScrollTop";
import "./Contact.css";
import Envelope from "../../assets/svg/envelope.svg";
import Phone from "../../assets/svg/phone.svg";
import Location from "../../assets/svg/location.svg";
import FB from "../../assets/svg/contact-fb.svg";
import LinkedIn from "../../assets/svg/contact-linkedin.svg";
import Telegram from "../../assets/svg/contact-telegram.svg";
import Insta from "../../assets/svg/contact-insta.svg";
// import Button from '../Common/Button/Button'
import { useTranslation } from "react-i18next";
import { useRef, useState, useEffect } from "react";
import axios from "axios";
import i18next from "i18next";

const Contact = () => {
  const { t } = useTranslation();
  const contactFormRef = useRef();
  const [formValues, setFormValues] = useState({
    full_name: "",
    email_address: "",
    title: "",
    company: "",
    title2: "",
    message: "",
  });
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [disble, setDisble] = useState(false);
  const [loading, setLoading] = useState("");
  const [redirectLang, setRedirectLang] = useState("");

  const sendEmail = async (e) => {
    setLoading("...sending");
    setDisble(true);
    e.preventDefault();
    const emailData = {
      full_name: formValues.full_name,
      email_address: formValues.email_address,
      title: formValues.title,
      company: formValues.company,
      message: formValues.message,
    };
    //console.log('Data:',emailData);
    try {
      const response = await axios.post(
        "https://webwork.co.in/supremefx/send_mail.php",
        emailData
      );
      if (response.status == 200) {
        alert(
          "Thank you for contacting us! A designated team member will reach out to you shortly! If urgent contact us at +971 52 680 9373"
        );
        setSuccessMsg(
          "Thank you for contacting us! A designated team member will reach out to you shortly! If urgent contact us at +971 52 680 9373"
        );
        setDisble(false);
        setLoading("");
      } else {
        alert("error sending message, try again!");
        console.log("error sending message, try again!");
        setErrorMsg("something went wrong, please try again");
        setLoading("");
        setDisble(false);
      }
    } catch (error) {
      alert("error sending message, try again!");
      setErrorMsg("something went wrong, please try again");
      setLoading("");
      setDisble(false);
    }

    setFormValues({
      full_name: "",
      email_address: "",
      title: "",
      company: "",
      message: "",
    });
  };

  const [contactAddress, setcontactAddress] = useState("");
  const [contactAddress2, setcontactAddress2] = useState("");
  const [contactEmail, setcontactEmail] = useState("");
  const [contactNumber, setcontactNumber] = useState("");
  const [contactNumber2, setcontactNumber2] = useState("");
  const [isCheck, setisCheck] = useState("");
  useEffect(() => {
    if (
      window.location.hostname === "supremefxtrading.sv" ||
      window.location.hostname === "www.supremefxtrading.sv"
    ) {
      setcontactAddress(
        "Euro House, Richmond Hill Road, P.O. Box 2897 Kingstown, St. Vincent and the Grenadines"
      );
      setcontactEmail("support@supremefxtrading.sv");
      setcontactNumber("+971 4 424 9027");
      setcontactAddress2("");
      setcontactNumber2("");
      setisCheck("0");
    } else if (
      window.location.hostname === "supremefxtrading.net" ||
      window.location.hostname === "www.supremefxtrading.net"
    ) {
      setcontactAddress(
        "Euro House, Richmond Hill Road, P.O. Box 2897 Kingstown, St. Vincent and the Grenadines."
      );
      setcontactAddress2(
        "Office No. 603, the Maze Tower, Shaikh Zayed Road, DIFC, Dubai, United Arab Emirates"
      );
      setcontactEmail("supportdesk@supremefxtrading.net");
      setcontactNumber("+971042270757");
      setisCheck("1");
      setcontactNumber2("");
    } else {
      setcontactAddress(
        ` <strong>Business Address:</strong> CT House, Office 4E, Providence, Mahe, Seychelles.<br>
          <strong>Registered Address:</strong> CT House, Office 9A, Providence, Mahe, Seychelles.`
    );    
      setcontactAddress2(
        "Office No. 603, the Maze Tower, Shaikh Zayed Road, DIFC, Dubai, United Arab Emirates"
      );
      setcontactEmail("support@supremefxtrading.com");
      setcontactNumber("+248 437 3177");
      setcontactNumber2("+971 4 227 0757");
      setisCheck("1");
    }
  }, [i18next.language, redirectLang]);

  return (
    <>
      <Navigation />
      <ScrollTop />
      <div className="section contact-section">
        <div className="container">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6">
                <div className="contact-us">
                  <div className="contact-content">
                    <h5>{t("Contact Us")}</h5>
                    <p className="p2">
                      {t(
                        "Connect with our expert support team and get the help you need to maximize your forex trading success."
                      )}
                    </p>
                    <p className="p2">
                      {t(
                        "Please let us know if you have a question, want to leave a comment, or would like further information about Supreme FX."
                      )}
                    </p>
                  </div>

                  <div className="contact-details">
                    <h5>Contact Details</h5>
                    <div className="contact-details">
                      <div className="contact-email">
                        <img src={Envelope} alt="" />
                        <p className="p4">
                          <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
                        </p>
                      </div>

                      <div className="contact-phone d-flex align-items-start">
                        <img src={Location} alt="" />
                        <p className="p4" dangerouslySetInnerHTML={{ __html: contactAddress }}></p>
                      </div>

                      <div className="contact-address">
                        <img src={Phone} alt="" />
                        <p className="p4">
                          <a href={`tel:${contactNumber}`}>{contactNumber}</a>
                        </p>
                      </div>

                      {isCheck == 1 && (
                        <>
                          <div className="contact-address d-flex align-items-start">
                            <img src={Location} alt="" />
                            <p className="p4">{contactAddress2}</p>
                          </div>
                        </>
                      )}

                      {contactNumber2 != "" && (
                        <>
                          <div className="contact-address">
                            <img src={Phone} alt="" />
                            <p className="p4">
                              <a href={`tel:${contactNumber2}`}>
                                {contactNumber2}
                              </a>
                            </p>
                          </div>
                        </>
                      )}

                      <div className="contact-social">
                        <a
                          href="https://www.facebook.com/SupremeFXofficial"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={FB} alt="" />
                        </a>
                        <a
                          href="https://www.linkedin.com/company/supreme-fx-trading"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={LinkedIn} alt="" />
                        </a>
                        <a
                          href="https://t.me/SUPREMETRADINGSIGNALS"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={Telegram} alt="" />
                        </a>
                        <a
                          href="https://www.instagram.com/supremefxofficial/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={Insta} alt="" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="contact-form">
                  <form type="POST" ref={contactFormRef} onSubmit={sendEmail}>
                    <div className="form-group">
                      <label htmlFor="full_name" className="form-label">
                        {t("Full Name")}
                      </label>
                      <input
                        type="text"
                        name="full_name"
                        className="form-control"
                        id="full_name"
                        value={formValues.full_name}
                        onChange={(e) =>
                          setFormValues({
                            ...formValues,
                            full_name: e.target.value,
                          })
                        }
                        placeholder={t("Full Name")}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="email_address" className="form-label">
                        {t("Email Address")}
                      </label>
                      <input
                        type="email"
                        name="email_address"
                        className="form-control"
                        id="email_address"
                        value={formValues.email_address}
                        onChange={(e) =>
                          setFormValues({
                            ...formValues,
                            email_address: e.target.value,
                          })
                        }
                        placeholder={t("Email Address")}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="title" className="form-label">
                        {t("Title")}
                      </label>
                      <input
                        type="text"
                        name="title"
                        className="form-control"
                        id="title"
                        value={formValues.title}
                        onChange={(e) =>
                          setFormValues({
                            ...formValues,
                            title: e.target.value,
                          })
                        }
                        placeholder={t("Title")}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="company" className="form-label">
                        {t("Company/Position")}
                      </label>
                      <input
                        type="text"
                        name="company"
                        className="form-control"
                        id="company"
                        value={formValues.company}
                        onChange={(e) =>
                          setFormValues({
                            ...formValues,
                            company: e.target.value,
                          })
                        }
                        placeholder={t("Company/Position")}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="message" className="form-label">
                        {t("Your Message")}
                      </label>
                      <textarea
                        name="message"
                        className="form-control"
                        id="message"
                        rows="5"
                        onChange={(e) =>
                          setFormValues({
                            ...formValues,
                            message: e.target.value,
                          })
                        }
                        placeholder={t("Your Message")}
                        value={formValues.message}
                      ></textarea>
                    </div>
                    <button
                      type="submit"
                      id="contactFormSubmit"
                      className="w-100 btn theme-red-btn"
                      disabled={disble}
                    >
                      {loading ? loading : "Send"}
                    </button>
                    {successMsg && (
                      <p className="text-success fw-bold mt-3 text-center">
                        {successMsg}
                      </p>
                    )}
                    {errorMsg && (
                      <p className="text-error fw-bold mt-3 text-center">
                        {errorMsg}
                      </p>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
